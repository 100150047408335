<template>
  <table-view
    ref="tableView"
    url="/dispatch/listForPage"
    delete-url="/dispatch/deleteById"
    :filterable="false"
    :filter-form="dataForm"
    :addable="isAuthed('dispatch_record_add')"
    :editable="(row) => isAuthed('dispatch_record_update')"
    :viewable="isAuthed('dispatch_record_view')"
    :batch-removable="isAuthed('dispatch_record_delete')"
    :headers="headers"
    :pageSize=100
    selectable
    show-index
    single
  >
    <el-form slot="search" @keyup.enter.native="query">
      <el-radio-group v-model="dataForm.day" @change="changeDateType">
        <el-radio label="1">今日</el-radio>
        <el-radio label="2">明日</el-radio>
        <el-radio label="3">
          <el-date-picker
            v-model="dataForm.dispatchTime"
            type="date"
            value-format="timestamp"
            placeholder="任务时间"
            :disabled="dataForm.day !== '3'"
          ></el-date-picker>
        </el-radio>
      </el-radio-group>
      <el-input v-model="dataForm.siteName" placeholder="工地名称" style="margin-left: 10px;"></el-input>
      <el-input v-model="dataForm.baseName" placeholder="所属基地"></el-input>
      <el-button @click="query" type="primary">查询</el-button>
    </el-form>
  </table-view>
</template>

<script>
import TableView from '@/components/templates/table-view'
// import CarListDropdown from '@/components/templates/car-list-dropdown'

export default {
  name: 'dispatch-record',

  components: { TableView },

  data () {
    return {
      headers: [
        { id: 1, prop: 'dispatchTime', label: '任务时间', width: 150, sortable: 'custom' },
        { id: 2, prop: 'siteName', label: '工地名称', minWidth: 250, sortable: 'custom' },
        { id: 3, prop: 'constructionPart', label: '施工部位', width: 120 },
        {
          id: 4,
          prop: 'dispatchStatus',
          label: '状态',
          width: 100,
          formatter: (row, column, cellValue) => {
            // 1 待完成 2 进行中 3 已完成
            switch (cellValue) {
              case 1:
                return '待完成'
              case 2:
                return '进行中'
              case 3:
                return '已完成'
              case 4:
                return '失败'
              case 5:
                return '待下发'
              case 6:
                return '待确认'
              default:
                return '未知'
            }
          },
          sortable: 'custom'
        },
        { id: 5, prop: 'quantityNumber', label: '方量数量', width: 100 },
        { id: 6, prop: 'demandCarSize', label: '需求车辆', width: 100 },
        { id: 7, prop: 'baseName', label: '所属基地', width: 100, sortable: 'custom' },
        { id: 8, prop: 'executeBaseName', label: '执行基地', width: 100 }
      ],
      dataForm: {
        day: '1',
        baseName: '',
        siteName: '',
        dispatchTime: ''
      }
    }
  },
  created () {
    this.dataForm.dispatchTime = this.getToday()
  },
  methods: {
    query () {
      if (this.dataForm.day === '1') {
        this.dataForm.dispatchTime = this.getToday()
      }
      if (this.dataForm.day === '2') {
        this.dataForm.dispatchTime = this.getTomorrow()
      }
      this.$refs.tableView.queryData()
    },
    changeDateType () {
      if (this.dataForm.day === '3') {
        this.dataForm.dispatchTime = ''
      }
    },
    getToday () {
      var day = new Date()
      day.setTime(day.getTime())
      return day.getFullYear() + '-' + (day.getMonth() + 1) + '-' + day.getDate()
    },
    getTomorrow () {
      var day = new Date()
      day.setTime(day.getTime() + 24 * 60 * 60 * 1000)
      return day.getFullYear() + '-' + (day.getMonth() + 1) + '-' + day.getDate()
    }
  }
}
</script>
